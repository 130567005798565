import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BodyMedium, BodySmall, BodySmallSemiBold } from '@src/components/styled/Typography';
import { PortfolioCard, PortfolioViewButtons } from '@entities/portfolio';
import { ReactComponent as ArrowCircleIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { ReactComponent as InfoIcon } from '@icons/wolfkit-solid/info-circle-solid.svg';
import { ReactComponent as ArrowIconComponent } from '@icons/wolfkit-light/arrow-left-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import EmptyPortfoliosList from './EmptyPortfoliosList';
import PortfolioAssetsBar from './PortfolioAssetsBar';
const WalletPortfoliosContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'hasError' && propName !== 'hasPortfolios' })(props => ({
    padding: `${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.s * 5}px 
              ${props.hasError ? props.theme.spacing_sizes.s * 5 : props.theme.spacing_sizes.m * 3}px`,
    background: '#fff',
    borderRadius: 10,
    boxShadow: '0px 4px 8px -6px rgba(24, 39, 75, 0.04)',
    gap: props.hasPortfolios ? props.theme.spacing_sizes.l : 'unset',
}));
const IconTextStyled = styled(IconText)(props => ({
    color: props.theme.palette.text.primary,
}));
const WalletPortfoliosMainContainer = styled(ContainerRow)(() => ({
    height: 56,
}));
const ExchangeIconStyled = styled(ExchangeIcon)(props => ({
    alignItems: 'center',
    marginRight: props.theme.spacing_sizes.m,
}));
const ExchangeOverviewTitle = styled(ContainerColumn)(() => ({
    justifyContent: 'center',
    flex: 1,
}));
const WalletErrorContainer = styled(ContainerRow)(props => ({
    justifyContent: 'end',
    alignItems: 'center',
    flex: 1,
    gap: props.theme.spacing_sizes.xs * 10,
    paddingRight: props.theme.spacing_sizes.xs * 2.5,
}));
const WalletOverviewControls = styled(ContainerRow)(props => ({
    height: 'auto',
    padding: `${props.theme.spacing_sizes.xs}px 0`,
    justifyContent: 'end',
    flex: 1,
}));
const ButtonsContainer = styled(ContainerRow)(props => ({
    width: 'auto',
    gap: props.theme.spacing_sizes.l,
    justifyContent: 'end',
}));
const ArrowIcon = styled(ArrowIconComponent)(() => ({
    width: 16,
    height: 16,
    transform: 'rotate(180deg)',
}));
const ExchangeName = styled(BodySmallSemiBold)(() => ({
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '22px',
}));
const WalletType = styled(BodySmall)(props => ({
    color: props.theme.palette.text.secondary,
    fontSize: 14,
    textTransform: 'uppercase',
}));
const PortfoliosList = styled(ContainerRow)(props => ({
    paddingTop: props.theme.spacing_sizes.l,
    flexWrap: 'wrap',
    gap: props.theme.spacing_sizes.xm,
}));
const BodyMediumStyled = styled(BodyMedium)();
const AddPortfolioButton = styled(Button, { shouldForwardProp: propName => propName !== 'viewType' })(props => ({
    width: props.viewType === 'card' ? 428 : '100%',
    height: props.viewType === 'card' ? 306 : 'auto',
    padding: props.theme.spacing_sizes.l,
    maxHeight: 'unset',
    minHeight: 'unset',
    borderRadius: 10,
    background: '#F8FAFF',
    border: `2px dashed ${props.theme.palette.primary.main}`,
    flexDirection: 'column',
    '& .MuiTypography-root': {
        color: props.theme.palette.primary.main,
    },
    [`${BodyMediumStyled}`]: {
        fontSize: 14,
        fontWeight: 500,
        color: props.theme.customColors.shades.primaryMidLight,
    },
}));
const WalletPortfolios = ({ wallet, portfoliosList, hasError = false, }) => {
    const { t } = useTranslation();
    const [activeViewType, setViewType] = useState('card');
    const [hoveredElement, setHoveredElement] = useState(undefined);
    const theme = useTheme();
    const onCardViewSelect = () => {
        if (activeViewType !== 'card') {
            setViewType('card');
        }
    };
    const onListViewSelect = () => {
        if (activeViewType !== 'list') {
            setViewType('list');
        }
    };
    const remainingSlots = portfoliosList.length > 0 && hasError === false ?
        10 - portfoliosList.length : undefined;
    const handleCardEnter = (portfolioId) => {
        setHoveredElement(portfolioId);
    };
    const handleCardLeave = () => {
        setHoveredElement(undefined);
    };
    return (_jsxs(WalletPortfoliosContainer, { hasError: hasError, hasPortfolios: Boolean(portfoliosList.length > 0), children: [_jsxs(WalletPortfoliosMainContainer, { children: [_jsx(ExchangeIconStyled, { type: wallet.exchange.type, size: [46, 46] }), _jsxs(ExchangeOverviewTitle, { children: [_jsx(ExchangeName, { children: wallet.exchange.properties.connectionName || '' }), _jsx(WalletType, { children: wallet.type || '' })] }), hasError === false ? (_jsx(WalletOverviewControls, { children: _jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'plain', color: 'primary-light', startIcon: ArrowCircleIcon }), _jsx(PortfolioViewButtons, { activeView: activeViewType, onChangeView: setViewType })] }) })) : (_jsxs(WalletErrorContainer, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: InfoIcon, color: theme.palette.warning.main })), text: t('portfolio.api_connection_failure') }), _jsx(Button, { endIcon: ArrowIcon, color: 'warning', size: 'small', children: t('navigation.go_to', {
                                    location: t('pages.overview.overview', { ns: 'common' }),
                                    ns: 'common',
                                }) })] }))] }), hasError === false && portfoliosList.length > 0 && (_jsx(PortfolioAssetsBar, { portfolios: portfoliosList, hoveredElement: hoveredElement, onAssetEnter: handleCardEnter, onAssetLeave: handleCardLeave })), hasError === false && portfoliosList.length > 0 ? (_jsxs(PortfoliosList, { children: [portfoliosList.map((portfolio) => (_jsx(PortfolioCard, { portfolio: portfolio, viewType: activeViewType, onMouseEnter: handleCardEnter, onMouseLeave: handleCardLeave, isHovered: portfolio.id === hoveredElement }, portfolio.id))), (remainingSlots && remainingSlots > 0) ? (_jsxs(AddPortfolioButton, { variant: 'tinted', color: 'primary', size: 'large', viewType: activeViewType, startIcon: PlusIcon, startIconProps: {
                            color: theme.palette.primary.main,
                        }, children: [t('portfolio.add_portfolio_button'), activeViewType === 'card' && (_jsx(BodyMediumStyled, { children: `${remainingSlots} ${t('plural_variables.available_portfolios', {
                                    ns: 'common',
                                    count: remainingSlots,
                                })}` }))] })) : null] })) :
                _jsx(EmptyPortfoliosList, { hasError: hasError, activeViewType: activeViewType })] }));
};
export default WalletPortfolios;
